import React, { useState } from 'react';
import { FaHome, FaEnvelope, FaSignInAlt, FaPlus, FaHeart, FaSearch, FaUser } from 'react-icons/fa';
import SignInModal from './SignInModal';
import ContactModal from './ContactModal';
import './Header.css';

const Header = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showSignInModal, setShowSignInModal] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);

  const handleLogin = () => {
    setIsLoggedIn(true);
    setShowSignInModal(false);
  };

  const handlePostProperty = () => {
    // Add logic for handling post property action
    console.log('Post Property clicked');
  };

  return (
    <header className="header">
      <div ><img className="logo" src="/LandLayout.com_PNG.png" alt="LandLayout Logo" /></div>
      <nav className="nav">
        {/* <a href="#home">Home</a> */}
        <a href="#layout">Land List</a>
        {/* <a href="#contact">Contact Us</a> */}
        {isLoggedIn ? (
          <a href="/profile" className="profile-link">
            <FaUser />
            <span>Profile</span>
          </a>
        ) : (
          <a href="#login" onClick={() => setShowSignInModal(true)}>Login</a>
        )}
        <div className="post-property">
          <button onClick={handlePostProperty} className="post-property-button">
            Sell Land<span className="badge">FREE</span>
          </button>
        </div>
      </nav>

      <div className="bottom-nav">
        <a href="#home" className="bottom-nav-item">
          <FaHome />
          <span>Home</span>
        </a>
        <a href="#wishlist" className="bottom-nav-item">
          <FaHeart />
          <span>Wishlists</span>
        </a>

        <button className="plus-button" onClick={() => setShowContactModal(true)}>
          <FaPlus />
        </button>

        <a href="#shortlist" className="bottom-nav-item">
          <FaSearch />
          <span>Explore</span>
        </a>
        {isLoggedIn ? (
          <a href="/profile" className="bottom-nav-item">
            <FaUser />
            <span>Profile</span>
          </a>
        ) : (
          <a href="#login" className="bottom-nav-item" onClick={() => setShowSignInModal(true)}>
            <FaSignInAlt />
            <span>Login</span>
          </a>
        )}
      </div>

      {showSignInModal && <SignInModal onClose={() => setShowSignInModal(false)} onLogin={handleLogin} />}
      {showContactModal && <ContactModal onClose={() => setShowContactModal(false)} />}
    </header>
  );
};

export default Header;