import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './LayoutDetails.css';
import { FaMapMarkerAlt,FaExclamationTriangle,FaEye, FaHeart, FaPhoneAlt,FaWhatsapp   } from 'react-icons/fa';
import ContactModal from './ContactModal';
import SignInModal from './SignInModal'; 

const layouts = [
    {
        image: 'https://plus.unsplash.com/premium_photo-1669227514138-d5e43441b5e7?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        title: 'Layout 1',
        description: 'Short description for layout 1',
        longdescription: 'Nestled amidst the serene hills of Paradise Valley, our estate offers an unparalleled blend of luxury living and natural beauty. Spanning over 200 acres of lush greenery and overlooking panoramic views of the valley, each plot is meticulously designed to harmonize with its surroundings.',
        images: [
            'https://plus.unsplash.com/premium_photo-1669227514138-d5e43441b5e7?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
            'https://images.unsplash.com/photo-1461175827210-5ceac3e39dd2?q=80&w=1933&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
            'https://images.unsplash.com/photo-1597174410299-51f25ccceb0d?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
            'https://images.unsplash.com/photo-1625378314354-0edfa5f28259?q=80&w=2043&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
        ],
        plots: [
            { id: 1, number: 'Plot 1', area: '100 sqm', priceRange: '₹100,000 - ₹150,000', address: '123 Main Road, Cityville' },
            { id: 2, number: 'Plot 2', area: '120 sqm', priceRange: '₹120,000 - ₹160,000', address: '456 Road, Vijayapura' },
            { id: 3, number: 'Plot 3', area: '110 sqm', priceRange: '₹110,000 - ₹155,000', address: '789 Road, Vijayapura' },
            { id: 4, number: 'Plot 4', area: '110 sqm', priceRange: '₹110,000 - ₹155,000', address: '888 Road Vijayapura' }
        ]
    },
    {
        image: 'https://plus.unsplash.com/premium_photo-1669227514138-d5e43441b5e7?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        title: 'Layout 2',
        description: 'Short description for layout 2',
        longdescription: 'Nestled amidst the serene hills of Paradise Valley, our estate offers an unparalleled blend of luxury living and natural beauty. Spanning over 200 acres of lush greenery and overlooking panoramic views of the valley, each plot is meticulously designed to harmonize with its surroundings.',
        images: [
            'https://plus.unsplash.com/premium_photo-1669227514138-d5e43441b5e7?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
            'https://images.unsplash.com/photo-1461175827210-5ceac3e39dd2?q=80&w=1933&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
            'https://images.unsplash.com/photo-1597174410299-51f25ccceb0d?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
            'https://images.unsplash.com/photo-1625378314354-0edfa5f28259?q=80&w=2043&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
        ],
        plots: [
            { id: 1, number: 'Plot 1', area: '100 sqm', priceRange: '₹100,000 - ₹150,000', address: '123 Main Road, Cityville' },
            { id: 2, number: 'Plot 2', area: '120 sqm', priceRange: '₹120,000 - ₹160,000', address: '456 Road, Vijayapura' },
            { id: 3, number: 'Plot 3', area: '110 sqm', priceRange: '₹110,000 - ₹155,000', address: '789 Road, Vijayapura' },
            { id: 4, number: 'Plot 4', area: '110 sqm', priceRange: '₹110,000 - ₹155,000', address: '888 Road Vijayapura' }
        ]
    },
    {
        image: 'https://plus.unsplash.com/premium_photo-1669227514138-d5e43441b5e7?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        title: 'Layout 3',
        description: 'Short description for layout 3',
        longdescription: 'Nestled amidst the serene hills of Paradise Valley, our estate offers an unparalleled blend of luxury living and natural beauty. Spanning over 200 acres of lush greenery and overlooking panoramic views of the valley, each plot is meticulously designed to harmonize with its surroundings.',
        images: [
            'https://plus.unsplash.com/premium_photo-1669227514138-d5e43441b5e7?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
            'https://images.unsplash.com/photo-1461175827210-5ceac3e39dd2?q=80&w=1933&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
            'https://images.unsplash.com/photo-1597174410299-51f25ccceb0d?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
            'https://images.unsplash.com/photo-1625378314354-0edfa5f28259?q=80&w=2043&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
        ],
        plots: [
            { id: 1, number: 'Plot 1', area: '100 sqm', priceRange: '₹100,000 - ₹150,000', address: '123 Main Road, Cityville' },
            { id: 2, number: 'Plot 2', area: '120 sqm', priceRange: '₹120,000 - ₹160,000', address: '456 Road, Vijayapura' },
            { id: 3, number: 'Plot 3', area: '110 sqm', priceRange: '₹110,000 - ₹155,000', address: '789 Road, Vijayapura' },
            { id: 4, number: 'Plot 4', area: '110 sqm', priceRange: '₹110,000 - ₹155,000', address: '888 Road Vijayapura' }
        ]
    },
    {
        image: 'https://plus.unsplash.com/premium_photo-1669227514138-d5e43441b5e7?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        title: 'Layout 4',
        description: 'Short description for layout 4',
        longdescription: 'Nestled amidst the serene hills of Paradise Valley, our estate offers an unparalleled blend of luxury living and natural beauty. Spanning over 200 acres of lush greenery and overlooking panoramic views of the valley, each plot is meticulously designed to harmonize with its surroundings.',
        images: [
            'https://plus.unsplash.com/premium_photo-1669227514138-d5e43441b5e7?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
            'https://images.unsplash.com/photo-1461175827210-5ceac3e39dd2?q=80&w=1933&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
            'https://images.unsplash.com/photo-1597174410299-51f25ccceb0d?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
            'https://images.unsplash.com/photo-1625378314354-0edfa5f28259?q=80&w=2043&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
        ],
        plots: [
            { id: 1, number: 'Plot 1', area: '100 sqm', priceRange: '₹100,000 - ₹150,000', address: '123 Main Road, Cityville' },
            { id: 2, number: 'Plot 2', area: '120 sqm', priceRange: '₹120,000 - ₹160,000', address: '456 Road, Vijayapura' },
            { id: 3, number: 'Plot 3', area: '110 sqm', priceRange: '₹110,000 - ₹155,000', address: '789 Road, Vijayapura' },
            { id: 4, number: 'Plot 4', area: '110 sqm', priceRange: '₹110,000 - ₹155,000', address: '888 Road Vijayapura' }
        ]
    },
    // Add more layouts as needed
];

const LayoutDetails = () => {
    const { id } = useParams();
    const [layout, setLayout] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isSignInModalOpen, setIsSignInModalOpen] = useState(false);

    const handleSignInClose = () => {
        setIsSignInModalOpen(false);
    };

    const handleShowNumberClick = () => {
        setIsSignInModalOpen(true);
    };
    
    const handleOpenModal = () => {
      setIsModalOpen(true);
    };
  
    const handleCloseModal = () => {
      setIsModalOpen(false);
    };
    useEffect(() => {
        const layoutData = layouts.find((l, index) => index.toString() === id);
        setLayout(layoutData);
        
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [id]);

    useEffect(() => {
        // Update the document title with the layout name when layout loads
        if (layout) {
            document.title = layout.title;
        }
    }, [layout]);

    const handleWhatsAppShare = () => {
        if (layout) {
            const layoutName = encodeURIComponent(layout.title);
            const currentURL = encodeURIComponent(window.location.href);
            const shareURL = `https://api.whatsapp.com/send?text=${layoutName}%20-%20${currentURL}`;
            window.open(shareURL, '_blank');
        }
    };
    if (!layout) return <div>Loading...</div>;

    const details = [
        { title: "31", subtitle: "acre mini city" },
        { title: "7.3", subtitle: "acres of open, green spaces" },
        { title: "12", subtitle: "thematic gardens" },
        { title: "5", subtitle: "large embankment parks" },
        { title: "500+", subtitle: "trees & shrubs" },
        { title: "800+", subtitle: "plots" },
        { title: "50", subtitle: "tree flavoured avenues" },
        { title: "15,000 SFT", subtitle: "proposed Clubhouse" },
        { title: "30KM", subtitle: "of road network" },
        { title: "20 & 22M", subtitle: "wide roads" }
    ];

    return (
        <div className="layout-details">
            <div className="breadcrumb">
    <Link to="/">Home</Link> 
    <span className="breadcrumb-separator">&gt;</span> 
    <Link to="/layouts">Layout List</Link> 
    <span className="breadcrumb-separator">&gt;</span> 
    <span>{layout.title}</span>
</div>

            <div className="layout-name">
                <h1>{layout.title}</h1>
            </div>
            
            {/* Responsive iFrame with "Open Interactive Map" Button */}
          {/* Responsive iFrame with "Open Interactive Map" Button */}
<div className="iframe-container">
    <button
        className="map-button"
        onClick={() =>
            window.open(
                id === '1'
                    ? 'https://landlayout.com/leaflet/leaflet2.html'
                    : 'https://landlayout.com/leaflet/leaflet.html',
                '_blank'
            )
        }
    >
        Open Interactive Map <FaMapMarkerAlt />
    </button>
    <iframe
        src={
            id === '1'
                ? 'https://landlayout.com/leaflet/leaflet2.html'
                : 'https://landlayout.com/leaflet/leaflet.html'
        }
        className="responsive-iframe"
        title="Interactive Layout Map"
    ></iframe>
</div>


<div className="layout-description">
                <div className="description-content">
                    <h2>Description</h2>
                    <p>{layout.longdescription}</p>
                </div>
                <div className="layout-sidebar">
                    <div className="sidebar-section">
                        <h2>Our Checks</h2>
                        <ul>
                            <li>Check 1</li>
                            <li>Check 2</li>
                            <li>Check 3</li>
                        </ul>
                    </div>
                    <div className="sidebar-section">
                        <h2>Other Details</h2>
                        <p>Additional details about the layout. Which helps to get more inside about the layout</p>
                    </div>
                </div>
            </div>

            {isMobile ? (
                <Carousel showArrows={false} showThumbs={false}>
                    {layout.images.map((image, index) => (
                        <div key={index}>
                            <img src={image} alt={`Layout ${index + 1}`} className="carousel-image-layout" />
                        </div>
                    ))}
                </Carousel>
            ) : (
                <div className="image-grid">
                    {layout.images.map((image, index) => (
                        <img key={index} src={image} alt={`Layout ${index + 1}`} className="grid-image" />
                    ))}
                </div>
            )}
             <div className="section-details">
    <h2>Layout Map View</h2>
    <p style={{ marginTop: '30px', marginBottom: '80px' }}>
        <a
            href={
                id === '1'
                    ? 'https://landlayout.com/leaflet/leaflet2.html'
                    : 'https://landlayout.com/leaflet/leaflet.html'
            }
            className="view-map-button"
            style={{ textDecoration: 'none' }}
        >
            View Map Of This Layout
        </a>
    </p>
</div>

 <section className="approval py-4">
        <div className="container">
          <div className="row">
            <div className="col-6 col-md-3 mb-2">
              <div className="app-box">
                <img className="w-100" src="https://pbavenuegarden.com/assets/img/new/BMICAPA@0.5x.png" alt="BMICAPA Approved" />
                {/* <p>BMICAPA APPROVED</p> */}
              </div>
            </div>
            <div className="col-6 col-md-3 mb-2">
              <div className="app-box">
                <img className="w-100" src="https://pbavenuegarden.com/assets/img/new/RERA@0.5x.png" alt="RERA Registered" />
                {/* <p>RERA REGISTERED</p> */}
              </div>
            </div>
            <div className="col-6 col-md-3 mb-2">
              <div className="app-box">
                <img className="w-100" src="https://pbavenuegarden.com/assets/img/new/MAJOR%20BANKS@0.5x.png" alt="Banks Approved" />
                {/* <p>BANKS APPROVED</p> */}
              </div>
            </div>
            <div className="col-6 col-md-3 mb-2">
              <div className="app-box">
                <img className="w-100" src="https://pbavenuegarden.com/assets/img/new/100%20CLEAR%20TITLES@0.5x.png" alt="Clear Titles" />
                {/* <p>CLEAR TITLES</p> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      

            {/* <div className="section-details">
                <h2>Layout Details</h2>
                <div className="details-grid">
                    {layout.plots.map(plot => (
                        <div key={plot.id} className="plot-details">
                            <h3>{plot.number}</h3>
                            <p><strong>Area:</strong> {plot.area}</p>
                            <p><strong>Price Range:</strong> {plot.priceRange}</p>
                            <p><strong>Address:</strong> {plot.address}</p>
                        </div>
                    ))}
                </div>
            </div> */}
         
         <section className="posted-by-section">
      <h4 className="posted-by-title">Posted by</h4>
      <div className="posted-by-content">
        <div className="profile-image">
          <img src="https://cdn-icons-png.flaticon.com/512/3135/3135768.png" alt="Image of the seller" />
        </div>
        <div className="profile-info">
          <div className="name-and-role">
            <p className="name">Land Layout</p>
            <div className="role">owner</div>
          </div>
          <div className="contact-buttons">
            <button className="whatsapp-button">
              <FaWhatsapp />
              <span>Whatsapp</span>
            </button>
            <button className="show-number-button" onClick={handleShowNumberClick}>
              <span>Show number</span>
            </button>
          </div>
        </div>
      </div>
    </section>
    
         <div className="details-section">
                <h1 style={{marginTop:"15px"}}>Key Features</h1>
                <div className="details-grid">
                    {details.map((detail, index) => (
                        <div key={index} className="detail-box">
                            <h3 className="detail-title">{detail.title}</h3>
                            <p className="detail-subtitle">{detail.subtitle}</p>
                        </div>
                    ))}
                </div>
            </div>

            <div className="table-container">
                <table className="table">
                    <thead>
                        <tr>
                            <th>Configuration</th>
                            <th>SBA (Sq.Ft.)</th>
                            <th>Price</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="fw-semibold">9X12M</td>
                            <td className="fw-semibold">1163</td>
                            <td className="fw-semibold">
                                <a href="#" className="price-btn">
                                    Unlock Price
                                    <i className="icon fa fa-lock"></i>
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <td className="fw-semibold">9X15M</td>
                            <td className="fw-semibold">1454</td>
                            <td className="fw-semibold">
                                <a href="#" className="price-btn">
                                    Unlock Price
                                    <i className="icon fa fa-lock"></i>
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <td className="fw-semibold">Large Size</td>
                            <td className="fw-semibold">2000+</td>
                            <td className="fw-semibold">
                                <a href="#" className="price-btn">
                                    Unlock Price
                                    <i className="icon fa fa-lock"></i>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="new-section">
    <h2 className="new-section-heading">Introducing Banglore's First-Ever Grade A+ Layout Development</h2>
    <div className="new-section-content">
        <div className="new-section-details">
            <div className="new-detail-item">
                <img src="https://pbavenuegarden.com/assets/img/icons/new/01.png" alt="Legal Approval Icon" />
                <p>100% legally secure and approved by major banks</p>
            </div>
            <div className="new-detail-item">
                <img src="https://pbavenuegarden.com/assets/img/icons/new/02.png" alt="Infrastructure Icon" />
                <p>Grade A+ infrastructure with stamped concrete roads</p>
            </div>
            <div className="new-detail-item">
                <img src="https://pbavenuegarden.com/assets/img/icons/new/03.png" alt="Underground Services Icon" />
                <p>All underground services</p>
            </div>
            <div className="new-detail-item">
                <img src="https://pbavenuegarden.com/assets/img/icons/new/04.png" alt="Avenues and Gardens Icon" />
                <p>10 Avenues & 10 Gardens inspired by the city of Mysuru</p>
            </div>
            <div className="new-detail-item">
                <img src="https://pbavenuegarden.com/assets/img/icons/new/05.png" alt="Open Space Icon" />
                <p>25 acres of Open Space</p>
            </div>
            <div className="new-detail-item">
                <img src="https://pbavenuegarden.com/assets/img/icons/new/06.png" alt="Stream Icon" />
                <p>Stream Flowing Within the Project</p>
            </div>
        </div>
        <div className="new-section-image">
            <img src="https://pbavenuegarden.com/assets/img/gallery/gal-022.webp" alt="Mysore Grade A+ Plotted Development" />
        </div>
    </div>
</div>



            <div className="container">
    <div className="row">
        <div className="col-md-3 mb-4">
            <div className="location-box p-3">
                <p className="fs-4 fw-bold">Hospital</p>
                <ul className="list-1">
                    <li>Manip Hospital 1 - 10 mins</li>
                    <li>Manip Hospital - 15 mins</li>
                    <li>Manip Hospital - 20 mins</li>
                    <li>Manip Hospital - 25 mins</li>
                </ul>
            </div>
        </div>
        <div className="col-md-3 mb-4">
            <div className="location-box p-3">
                <p className="fs-4 fw-bold">School</p>
                <ul className="list-1">
                    <li>Maharaja Inst 1 - 10 mins</li>
                    <li>Maharaja Inst 2 - 15 mins</li>
                    <li>Maharaja Inst 3 - 20 mins</li>
                    <li>Maharaja Inst 4 - 25 mins</li>
                </ul>
            </div>
        </div>
        <div className="col-md-3 mb-4">
            <div className="location-box p-3">
                <p className="fs-4 fw-bold">College</p>
                <ul className="list-1">
                    <li>Flos Carmelio 1 - 10 mins</li>
                    <li>Flos Carmelio 2 - 15 mins</li>
                    <li>Flos Carmelio 3 - 20 mins</li>
                    <li>Flos Carmelio 4 - 25 mins</li>
                </ul>
            </div>
        </div>
        <div className="col-md-3 mb-4">
            <div className="location-box p-3">
                <p className="fs-4 fw-bold">Social</p>
                <ul className="list-1">
                    <li>Mysore Palace 1 - 10 mins</li>
                    <li>Mysore Palace 2 - 15 mins</li>
                    <li>Mysore Palace 3 - 20 mins</li>
                    <li>Mysore Palace 4 - 25 mins</li>
                </ul>
            </div>
        </div>
    </div>
</div>

           
       

            <center>
            <div>
      {/* Contact Seller Button */}
      <center>
        <h2 className="contact-heading">Schedule a Site Visit</h2>
        <div > {/* Add padding to ensure space */}
  <button 
    className="submit-button contact-seller-button" 
    onClick={handleOpenModal}
    style={{ height: '40px', width: '150px' }}  // Inline height and width
  >
    Contact Seller
  </button>
</div>


      </center>

      <div className="activity-section">
    <h2 className="activity-heading">Activity On This Property</h2>
    <div className="activity-stats">
        <div className="activity-item">
            <img src="https://img.icons8.com/ios-glyphs/30/user.png" alt="Profile Icon" />
            <p>45 Unique Views</p>
        </div>
        <div className="activity-item">
            <img src="https://img.icons8.com/ios-glyphs/30/like.png" alt="Heart Icon" />
            <p>5 Shortlists</p>
        </div>
        <div className="activity-item">
            <img src="https://img.icons8.com/ios-glyphs/30/phone.png" alt="Phone Icon" />
            <p>4 Contacted</p>
        </div>
    </div>
</div>


    {/* New Report Section */}

    <div className="report-section">
  <div className="report-box">
    <FaExclamationTriangle className="warning-icon" />
    <h3>Report what was not correct in this property</h3>
    <div className="report-options">
      <button className="report-button" value="broker">
        Listed by Broker
      </button>
      <button className="report-button" value="sold-out">
        Sold Out
      </button>
      <button className="report-button" value="wrong-info">
        Wrong Info
      </button>
    </div>
  </div>
</div>

      
        <div style={{ paddingBottom: '100px' }}>
        <div className="disclaimer-section">
        <h2 className="disclaimer-heading">Disclaimer</h2>
        <p className="disclaimer-text">
          Land Layout has endeavoured to ascertain the requirement of RERA registration. However, the advertiser claims that there is no requirement for such registration. Users are cautioned accordingly.
        </p>
        <p className="disclaimer-text">
          Land Layout acts merely as a medium for posting information and content. It is clarified that the data available on the website is not physically verified and hence no representation or warranty is expressly or impliedly given to its accuracy in any form. The User is advised to do thorough research and due diligence before making any decision regarding investment. Nothing contained on the platform shall deem to constitute legal advice, solicitation, invitation etc. in any form whatsoever.
        </p>
      </div>
      </div>
      {/* Modal */}
      {isModalOpen && <ContactModal onClose={handleCloseModal} />}

      {isSignInModalOpen && <SignInModal onClose={handleSignInClose} />}
    </div>
        </center>
        <div className="whatsapp-share">
    <div className="whatsapp-icon" onClick={handleWhatsAppShare}>
        <img src="https://upload.wikimedia.org/wikipedia/commons/5/5e/WhatsApp_icon.png" alt="WhatsApp Icon" />
    </div>
    <span className="share-text" onClick={handleWhatsAppShare}>Share</span>
</div>

        </div>
    );
};

export default LayoutDetails;
