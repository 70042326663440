import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { Carousel } from 'react-responsive-carousel';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { useSwipeable } from 'react-swipeable';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './Homepage.css';

const Homepage = () => {
  const [currentIndexFeatured, setCurrentIndexFeatured] = useState(0);
  const [currentIndexLayoutList, setCurrentIndexLayoutList] = useState(0);
  
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const navigate = useNavigate(); // Initialize useNavigate

  const cards = [
    {
      image: 'https://plus.unsplash.com/premium_photo-1669227514138-d5e43441b5e7?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      title: 'Layout 1',
      description: 'Short description for layout 1',
    },
    {
      image: 'https://images.unsplash.com/photo-1461175827210-5ceac3e39dd2?q=80&w=1933&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      title: 'Layout 2',
      description: 'Short description for layout 2',
    },
    {
      image: 'https://images.unsplash.com/photo-1597174410299-51f25ccceb0d?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      title: 'Layout 3',
      description: 'Short description for layout 3',
    },
    {
      image: 'https://images.unsplash.com/photo-1625378314354-0edfa5f28259?q=80&w=2043&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      title: 'Layout 4',
      description: 'Short description for layout 4',
    },
    // Add more cards as needed
  ];

  const handlePrev = (section) => {
    if (section === 'featured') {
      const newIndex = currentIndexFeatured - (isMobile ? 1 : 3);
      setCurrentIndexFeatured(newIndex < 0 ? 0 : newIndex);
    } else if (section === 'layoutList') {
      const newIndex = currentIndexLayoutList - (isMobile ? 1 : 3);
      setCurrentIndexLayoutList(newIndex < 0 ? 0 : newIndex);
    }
  };
  
  const handleNext = (section) => {
    if (section === 'featured') {
      const newIndex = currentIndexFeatured + (isMobile ? 1 : 3);
      setCurrentIndexFeatured(newIndex >= cards.length ? cards.length - 1 : newIndex);
    } else if (section === 'layoutList') {
      const newIndex = currentIndexLayoutList + (isMobile ? 1 : 3);
      setCurrentIndexLayoutList(newIndex >= cards.length ? cards.length - 1 : newIndex);
    }
  };
  

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const swipeHandlersFeatured = useSwipeable({
    onSwipedLeft: () => handleNext('featured'),
    onSwipedRight: () => handlePrev('featured'),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });
  
  const swipeHandlersLayoutList = useSwipeable({
    onSwipedLeft: () => handleNext('layoutList'),
    onSwipedRight: () => handlePrev('layoutList'),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });
  

  const handleCardClick = (index) => {
    navigate(`/layout-details/${index}`);
  };

  const testimonials = [
    {
      text: "LandLayout.com made finding our dream plot so easy. The detailed aerial images gave us a clear understanding of the land without even visiting the site.",
      author: "Sharanu B.",
    },
    {
      text: "I was able to compare multiple plots quickly and efficiently. The convenience of viewing everything online saved me a lot of time and hassle.",
      author: "Rahul.",
    },
    {
      text: "The clarity of the property on LandLayout.com gave me confidence in my purchase. I highly recommend this platform to anyone looking to buy land.",
      author: "Anjali M.",
    },
    {
      text: "Being able to view high-quality aerial images and clear plot dimensions from home was a game-changer. LandLayout.com made the entire buying process smooth and stress-free.",
      author: "Vikram R.",
    },
  ];

  return (
    <div className="homepage">
          <div className="video-background">
        <video autoPlay loop muted playsInline>
          <source src="https://cdn.pixabay.com/video/2019/09/24/27197-362173819_large.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="video-overlay">
          <h1>Lay Out the Land: Connecting Buyers and Sellers.</h1>
          {/* <p>LandLayout.com</p> */}
        </div>
      </div>
      {/* <Carousel
        showArrows={true}
        infiniteLoop={true}
        showThumbs={false}
        showStatus={false}
        autoPlay={true}
        interval={6000}
      >
        <div className='carousel-image'>
        <video autoPlay loop muted playsInline className="carousel-image">
          <source src="https://cdn.pixabay.com/video/2019/09/24/27197-362173819_large.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
          <div className="carousel-text">
            <p className="carousel-title">Revolutionize Your Land Search with</p>
            <p className="carousel-subtitle">LandLayout.com</p>
          </div>
        </div>
        <div className='carousel-image'>
        <video autoPlay loop muted playsInline className="carousel-image">
          <source src="https://cdn.pixabay.com/video/2023/07/18/172022-846482721_large.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
          <img src="https://media.istockphoto.com/id/1338058166/photo/land-or-landscape-of-green-field-in-aerial-view-and-home-or-house-icon.jpg?s=2048x2048&w=is&k=20&c=Otsrh9yYrFOXJ3Cm4OKX5sQqRcFhvKzSVu3yZuRCdJ8=" alt="Slide 2" className="carousel-image" />
          <div className="carousel-text">
            <p className="carousel-title">Revolutionize Your Land Search with</p>
            <p className="carousel-subtitle">LandLayout.com</p>
          </div>
        </div>
        <div className='carousel-image'>
          <img src="https://images.unsplash.com/photo-1461175827210-5ceac3e39dd2?q=80&w=1933&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Slide 3" className="carousel-image" />
          <div className="carousel-text">
            <p className="carousel-title">Revolutionize Your Land Search with</p>
            <p className="carousel-subtitle">LandLayout.com</p>
          </div>
        </div>
      </Carousel> */}
     {/* Main Headline */}
  <h1 className="main-headline">Discover Your Ideal Land with High-Quality Aerial Imagery and Clear Dimensions</h1>

{/* Search Bar */}
<center>
  <div className="search-bar">
    <input type="text" className="search-input" placeholder="  City / LLID"/>
    <select className="search-select">
      <option value="">Select Land Type</option>
      <option value="layout">Layout</option>
      <option value="plot">Plot</option>
      <option value="farmland">Farmland</option>
      <option value="commercial-land">Commercial Land</option>
    </select>
    <button className="search-button">🔍 Search</button>
  </div>
</center>




<h2 className="card-list-heading">Featured Layouts</h2>
<div className="card-list-container" {...swipeHandlersLayoutList}>
  {currentIndexLayoutList > 0 && !isMobile && <FaArrowLeft onClick={() => handlePrev('layoutList')} className="arrow-icon" />}
  <div className="card-list">
    {cards.slice(currentIndexLayoutList, currentIndexLayoutList + (isMobile ? 1 : 3)).map((card, index) => (
    <div key={index} className="card" onClick={() => handleCardClick(currentIndexLayoutList + index)}>
    <img src={card.image} alt={card.title} className="card-image" />
    <div className="card-content">
      <h3 className="card-title">{card.title}</h3>
      <p className="card-description">{card.description}</p>
    </div>
  </div>
  
    ))}
  </div>
  {currentIndexLayoutList < cards.length - (isMobile ? 1 : 3) && !isMobile && <FaArrowRight onClick={() => handleNext('layoutList')} className="arrow-icon" />}
</div>

      
{/* New Section: Why use LandLayout? */}
<section className="why-use-section">
      <h2 className="why-use-heading">Why use LandLayout?</h2>
      <div className="why-use-container">
        <div className="why-use-box">
          <img src="https://cdn-icons-png.flaticon.com/512/471/471012.png" alt="Explore with Ease" />
          <p className="why-use-text">Explore with Ease: View high-quality aerial images of plots from home.</p>
        </div>
        <div className="why-use-box">
          <img src="https://cdn-icons-png.flaticon.com/512/16493/16493765.png" alt="Clear Dimensions" />
          <p className="why-use-text">Clear Dimensions: Access accurate plot dimensions instantly.</p>
        </div>
        <div className="why-use-box">
          <img src="https://cdn-icons-png.flaticon.com/512/1584/1584849.png" alt="Save Time" />
          <p className="why-use-text">Save Time: Avoid unnecessary site visits with online views.</p>
        </div>
        <div className="why-use-box">
          <img src="https://cdn-icons-png.flaticon.com/512/1509/1509666.png" alt="Unique IDs" />
          <p className="why-use-text">Unique IDs: Easily share and track properties with unique IDs.</p>
        </div>
      </div>
    </section>
    <div className="testimonial-section">
  <Carousel
    showArrows={true}
    showStatus={false}
    showIndicators={false}
    infiniteLoop
    autoPlay
    interval={3000}
    stopOnHover
    showThumbs={false}
  >
    {testimonials.map((testimonial, index) => (
      <div className="testimonial" key={index}>
        {/* <img src="https://cdn-icons-png.flaticon.com/512/6314/6314351.png" alt="Quote Icon" className="quote-icon" /> */}
        <p>"{testimonial.text}"</p>
        <h4>- {testimonial.author}</h4>
      </div>
    ))}
  </Carousel>
</div>
    <div className="features-section">
  <h2 className="features-heading">Key Features of LandLayout</h2>
  <div className="features-grid">
    <div className="feature-item">
      <div className="icon-container">
        <img src="https://cdn-icons-png.flaticon.com/512/3807/3807158.png" alt="Map Marker" className="icon-image" />
      </div>
      <h3>Revolutionize Your Land Search</h3>
      <p>Experience a new way of finding your perfect plot with high-resolution aerial imagery and detailed plot dimensions.</p>
    </div>
    <div className="feature-item">
      <div className="icon-container">
        <img src="https://cdn-icons-png.flaticon.com/512/2163/2163350.png" alt="Home" className="icon-image" />
      </div>
      <h3>View Plots from Home</h3>
      <p>Avoid unnecessary site visits. Get a clear and comprehensive view of the land from the comfort of your home.</p>
    </div>
    <div className="feature-item">
      <div className="icon-container">
        <img src="https://cdn-icons-png.flaticon.com/512/3437/3437338.png" alt="Chart Line" className="icon-image" />
      </div>
      <h3>Easy Comparisons and Sharing</h3>
      <p>Compare multiple plots effortlessly with easily shareable property IDs. Share your favorite properties with friends and family.</p>
    </div>
    <div className="feature-item">
      <div className="icon-container">
        <img src="https://cdn-icons-png.flaticon.com/512/3967/3967544.png" alt="Check Circle" className="icon-image" />
      </div>
      <h3>Transparent and Trustworthy</h3>
      <p>Enjoy a transparent buying process with accurate, up-to-date information and clear visualizations of the land.</p>
    </div>
    <div className="feature-item">
      <div className="icon-container">
        <img src="https://cdn-icons-png.flaticon.com/512/7021/7021218.png" alt="Chart Bar" className="icon-image" />
      </div>
      <h3>For Sellers and Developers</h3>
      <p>Monitor interest with built-in CRM tools and gain valuable insights into user views, likes, and saved plots to optimize your listings.</p>
    </div>
    <div className="feature-item">
      <div className="icon-container">
        <img src="https://cdn-icons-png.flaticon.com/512/610/610128.png" alt="Trending Up" className="icon-image" />
      </div>
      <h3>Market Insights</h3>
      <p>Stay informed with the latest trends in the Indian real estate market, including the growing demand for larger residential spaces and affordable housing initiatives.</p>
    </div>
  </div>
</div>
{/* <h2 className="card-list-heading">Featured Layouts</h2>
<div className="card-list-container" {...swipeHandlersFeatured}>
  {currentIndexFeatured > 0 && !isMobile && <FaArrowLeft onClick={() => handlePrev('featured')} className="arrow-icon" />}
  <div className="card-list">
    {cards.slice(currentIndexFeatured, currentIndexFeatured + (isMobile ? 1 : 3)).map((card, index) => (
     <div key={index} className="card" onClick={() => handleCardClick(currentIndexFeatured + index)}>
     <img src={card.image} alt={card.title} className="card-image" />
     <div className="card-content">
       <h3 className="card-title">{card.title}</h3>
       <p className="card-description">{card.description}</p>
     </div>
   </div>
   
    ))}
  </div>
  {currentIndexFeatured < cards.length - (isMobile ? 1 : 3) && !isMobile && <FaArrowRight onClick={() => handleNext('featured')} className="arrow-icon" />}
</div> */}

      {/* <h2 className="contact-heading">Contact Us</h2>
      <div className="contact-container">
        <form className="contact-form">
          <div className="form-group">
            <label htmlFor="fullName">Full Name</label>
            <input type="text" id="fullName" name="fullName" required />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input type="email" id="email" name="email" required />
          </div>
          <div className="form-group">
            <label htmlFor="phone">Phone Number</label>
            <input type="tel" id="phone" name="phone" required />
          </div>
          <div className="form-group">
            <label htmlFor="info">Info</label>
            <input type="text" id="info" name="info" required />
          </div>
          <button type="submit" className="submit-button">Upload Images</button>
          <button type="submit" className="submit-button">Submit</button>
        </form>
        <div className="contact-info">
          <h3>Contact Information</h3>
          <p>Near Gandi Chowk Vijayapura</p>
          <p>Email: contact@landlayout.com</p>
          <p>Phone: 9933933333</p>
        </div>
      </div> */}
      
  


<footer className="footer">
  <div className="footer-links">
    <a href="/about-us" style={{ color: 'white', textDecoration: 'none' }}>About Us</a> | 
    <a href="/contact" style={{ color: 'white', textDecoration: 'none' }}>Contact</a> | 
    <a href="/faqs" style={{ color: 'white', textDecoration: 'none' }}>FAQs</a>
  </div>
  <div className="footer-social">
    <a href="https://www.facebook.com/landlayout" target="_blank" rel="noopener noreferrer">
      <img src="https://cdn-icons-png.flaticon.com/512/5968/5968764.png" alt="Facebook" className="social-icon" />
    </a>
    <a href="https://twitter.com/landlayout" target="_blank" rel="noopener noreferrer">
      <img src="https://cdn-icons-png.flaticon.com/512/3670/3670151.png" alt="Twitter" className="social-icon" />
    </a>
    <a href="https://www.instagram.com/landlayout/" target="_blank" rel="noopener noreferrer">
      <img src="https://cdn-icons-png.flaticon.com/512/15707/15707749.png" alt="Instagram" className="social-icon" />
    </a>
  </div>
  <p>&copy; 2024 Land Layout. All rights reserved.</p>
</footer>

    </div>
  );
};

export default Homepage;
